import { MDText } from 'i18n-react';

import { stripStringAndCamelCase } from 'Utilities';
import routing, { AFFILIATE_PAGE_ROUTES, BRANDS_SECTION_ROUTES, SITEMAP_HTML_URL } from 'Utilities/routing';
import { CAREERS_URL, CONTACT_URL, HELP_URL, INSTANT_MATCH_URL, PRO_URL } from 'Utilities/urls';
import {
  FULL_LANDING_PAGE_ROUTES,
  GUIDES_PATH,
  MOT_PAGE_ROUTES,
  MOT_VALUATION_ROUTE,
  NO_SITEMAP_ROUTES,
  SELL_MY_CAR_PATH,
  TAX_PAGE_ROUTES,
  TAX_VALUATION_ROUTE,
  WORDPRESS_ROUTES,
} from 'Utilities/wordpress';

import { AUTHENTICATION_URLS } from './containers/app/authentication/helpers';
import { ULEZ_URL_PATH } from './containers/app/contentPages/ulezPage/helper';
import { CUSTOMER_HUB_PATHS, CUSTOMER_HUB_RESULT_ROUTE, CUSTOMER_HUB_VALUATION_ROUTE } from './containers/app/customerHub/CustomerHubHelpers';
import { SEO_PAGES_ROUTES } from './containers/app/SeoPages/helpers';
import Texts from './texts.json';

const T = new MDText(Texts);

const ulezCheckerFooterRoutes = Object.freeze([
  ULEZ_URL_PATH,
]);

const routes = [
  {
    cache: true,
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('homeView.title'),
    meta: {
      description: T.translate('homeView.metaDescription'),
      title: T.translate('homeView.metaTitle'),
    },
    path: '/',
    showDisclaimer: true,
    showDisclaimerDetail: true,
    showInstantMatchBrowseModule: true,
  },
  {
    cache: true,
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('referView.title'),
    meta: {
      description: T.translate('referView.metaDescription'),
      title: T.translate('referView.metaTitle'),
    },
    path: '/refer',
    showDisclaimer: true,
    showDisclaimerDetail: true,
  },
  {
    cache: true,
    hideReviews: false,
    label: T.translate('components.navigation.howItWorksLabel'),
    meta: {
      description: T.translate('howItWorksView.metaDescription'),
      title: T.translate('howItWorksView.metaTitle'),
    },
    nav: true,
    path: '/how-it-works',
    showDisclaimer: true,
    showDisclaimerDetail: true,
    zendeskWidget: true,
  },
  {
    cache: true,
    hideReviews: true,
    label: T.translate('customerReviewsView.title'),
    meta: {
      description: T.translate('customerReviewsView.metaDescription'),
      title: T.translate('customerReviewsView.metaTitle'),
    },
    path: '/reviews',
    zendeskWidget: true,
  },
  {
    cache: true,
    hideReviews: true,
    label: T.translate('helpView.title'),
    nav: true,
    path: HELP_URL,
  },
  {
    label: T.translate('components.navigation.toolsLabel'),
    name: 'tools',
    nav: true,
    subRoutes: [
      { label: T.translate('components.navigation.valueTrackerLabel'), path: CUSTOMER_HUB_VALUATION_ROUTE },
      { label: T.translate('components.navigation.instantMatchLabel'), path: `${INSTANT_MATCH_URL}?utm_source=mw&utm_medium=header`, trackingPath: INSTANT_MATCH_URL },
      { badge: T.translate('components.navigation.badgeNew'), label: T.translate('components.navigation.motLabel'), path: MOT_VALUATION_ROUTE },
      { badge: T.translate('components.navigation.badgeNew'), isNewRoute: true, label: T.translate('components.navigation.taxCheckLabel'), path: TAX_VALUATION_ROUTE },
      { label: T.translate('components.navigation.ulezLabel'), path: '/ulez-checker' },
    ],
  },
  {
    label: T.translate('components.navigation.moreLabel'),
    name: 'more',
    nav: true,
    subRoutes: [
      { label: T.translate('aboutView.title'), path: '/about' },
      { label: T.translate('guidesView.title'), path: GUIDES_PATH },
      { label: T.translate('moreMoneyStoriesView'), path: '/stories' },
      { label: T.translate('jobsView.title'), path: CAREERS_URL },
      { label: T.translate('components.navigation.motorwayForDealersLabel'), path: PRO_URL },
      { label: T.translate('contactView.title'), path: CONTACT_URL },
    ],
  },
  {
    cache: true,
    hideReviews: false,
    label: T.translate('aboutView.title'),
    meta: { description: T.translate('aboutView.metaDescription'), title: T.translate('aboutView.metaTitle') },
    path: '/about',
    wordpress: false,
    zendeskWidget: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('vehiclePayment.title'),
    meta: { title: T.translate('vehiclePayment.metaTitle') },
    noRobots: true,
    path: `${routing.vrmPath}/vehicle-payment`,
    vrmPath: true,
  },
  {
    cache: true,
    hideReviews: true,
    label: T.translate('brandsBaseView.title'),
    meta: { description: T.translate('brandsBaseView.metaDescription'), title: T.translate('brandsBaseView.metaTitle') },
    path: BRANDS_SECTION_ROUTES[0],
    refreshMetaManually: true,
    showDisclaimer: true,
    showDisclaimerDetail: true,
  },
  {
    hideVehicleLookup: true,
    label: T.translate('signUpView.eligible.title'),
    meta: { description: T.translate('signUpView.metaDescription'), title: T.translate('signUpView.metaTitle') },
    noRobots: true,
    path: `${routing.vrmPath}/sign-up`,
    vrmPath: true,
  },
  {
    // Old profiling form, we can't delete this yet otherwise the redirect to `/{vrm}/profile` won't work
    noRobots: true,
    path: `${routing.vrmPath}/vehicle-details/:step?`,
    vrmPath: true,
  },
  {
    hideFooter: true,
    label: T.translate('detailsFormView.title'),
    manageOwnScroll: true,
    meta: { title: T.translate('detailsFormView.metaTitle') },
    noRobots: true,
    path: `${routing.vrmPath}/profile/:step?/:step?/:step?`,
    vrmPath: true,
    zendeskWidget: false,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('signInView.title'),
    meta: { description: T.translate('signInView.metaDescription'), title: T.translate('signInView.metaTitle') },
    noRobots: true,
    path: '/sign-in',
    showFooterSpacer: true,
  },
  {
    hideFooter: true,
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('passwordResetView.title'),
    meta: { description: T.translate('passwordResetView.metaDescription'), title: T.translate('passwordResetView.metaTitle') },
    noRobots: true,
    path: AUTHENTICATION_URLS.PASSWORD_RESET,
  },
  {
    hideFooter: true,
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('forgotPasswordView.title'),
    meta: { description: T.translate('forgotPasswordView.metaDescription'), title: T.translate('forgotPasswordView.metaTitle') },
    noRobots: true,
    path: AUTHENTICATION_URLS.FORGOT_PASSWORD,
  },
  {
    hideVehicleLookup: true,
    label: T.translate('tokenExpiredView.title'),
    meta: { description: T.translate('tokenExpiredView.metaDescription'), title: T.translate('tokenExpiredView.metaTitle') },
    noRobots: true,
    path: '/token-expired',
  },
  {
    hideReviews: false,
    hideVehicleLookup: true,
    label: T.translate('vehicleView.title'),
    meta: { description: T.translate('vehicleView.metaDescription') },
    noRobots: true,
    path: `${routing.vrmPath}`,
    showDisclaimer: true,
    showDisclaimerDetail: true,
    showFooterSpacer: true,
    vrmPath: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('accountView.title'),
    meta: { description: T.translate('accountView.metaDescription'), title: T.translate('accountView.metaTitle') },
    path: '/account',
    zendeskWidget: true,
  },
  {
    cache: true,
    label: T.translate('checkoutView.title'),
    meta: { title: T.translate('checkoutView.metaTitle') },
    noState: true,
    path: '/checkout/:buyer/:failed?',
  },
  {
    hideReviews: true,
    meta: { title: T.translate('unsubscribePage.metaTitle') },
    path: '/unsubscribe/:sms?',
  },
  {
    path: '/dealers',
  },
  {
    path: '/pro',
  },
  {
    path: '/[vrm]*',
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('acceptOffer.title'),
    meta: {
      description: T.translate('signUpView.metaDescription'),
      title: T.translate('acceptOffer.metaTitle'),
    },
    noRobots: true,
    path: `${routing.vrmPath}/accept-offer`,
    vrmPath: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    noRobots: true,
    path: '/unsupported-browser',
    showFooterSpacer: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('confirmSaleSummary.title'),
    meta: {
      description: T.translate('signUpView.metaDescription'),
      title: T.translate('confirmSaleSummary.metaTitle'),
    },
    noRobots: true,
    path: `${routing.vrmPath}/confirm-sale-summary`,
    vrmPath: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('reviewStatus.title'),
    meta: {
      description: T.translate('signUpView.metaDescription'),
      title: T.translate('reviewStatus.metaTitle'),
    },
    noRobots: true,
    path: `${routing.vrmPath}/review-status`,
    vrmPath: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('dealerDetails.title'),
    meta: {
      description: T.translate('signUpView.metaDescription'),
      title: T.translate('dealerDetails.metaTitle'),
    },
    noRobots: true,
    path: `${routing.vrmPath}/purchasing-dealer-details`,
    vrmPath: true,
  },
  {
    hideFooter: true,
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('confirmSale.title'),
    meta: {
      description: T.translate('signUpView.metaDescription'),
      title: T.translate('confirmSale.metaTitle'),
    },
    noRobots: true,
    path: `${routing.vrmPath}/confirm-sale`,
    vrmPath: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('addBankDetails.title'),
    meta: {
      description: T.translate('signUpView.metaDescription'),
      title: T.translate('addBankDetails.metaTitle'),
    },
    noRobots: true,
    path: `${routing.vrmPath}/add-bank-details`,
    vrmPath: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('uploadDocuments.title'),
    meta: {
      description: T.translate('signUpView.metaDescription'),
      title: T.translate('uploadDocuments.metaTitle'),
    },
    noRobots: true,
    path: `${routing.vrmPath}/upload-documents/:kind?`,
    vrmPath: true,
  },
  {
    hideReviews: true,
    label: T.translate('sitemap.title'),
    meta: {
      description: T.translate('sitemap.metaDescription'),
      title: T.translate('sitemap.metaTitle'),
    },
    path: SITEMAP_HTML_URL,
  },
  {
    hideReviews: true,
    label: T.translate('homeView.title'),
    meta: {
      description: T.translate('homeView.metaDescription'),
      title: T.translate('homeView.metaTitle'),
    },
    noRobots: true,
    path: AFFILIATE_PAGE_ROUTES[0],
    showDisclaimer: true,
    showDisclaimerDetail: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('homeView.title'),
    meta: {
      description: T.translate('homeView.metaDescription'),
      title: T.translate('homeView.metaTitle'),
    },
    path: `${routing.vrmPath}/request/bank-details`,
    showDisclaimer: true,
    showDisclaimerDetail: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('homeView.title'),
    meta: {
      description: T.translate('homeView.metaDescription'),
      title: T.translate('homeView.metaTitle'),
    },
    path: `${routing.vrmPath}/request/bank-details/confirmed`,
    showDisclaimer: true,
    showDisclaimerDetail: true,
  },
  {
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('homeView.title'),
    meta: {
      description: T.translate('homeView.metaDescription'),
      title: T.translate('homeView.metaTitle'),
    },
    path: `${routing.vrmPath}/get-ahead/upload-documents/:kind?`,
    showDisclaimer: true,
    showDisclaimerDetail: true,
  },
  {
    hideFooter: true,
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('homeView.title'),
    meta: {
      description: T.translate('homeView.metaDescription'),
      title: T.translate('homeView.metaTitle'),
    },
    path: `${routing.vrmPath}/vehicle-details`,
    showDisclaimer: false,
    showDisclaimerDetail: false,
  },
]
  .concat(FULL_LANDING_PAGE_ROUTES.map((r) => ({
    cache: true,
    hideReviews: true,
    hideVehicleLookup: r === SELL_MY_CAR_PATH,
    label: T.translate(`landing${stripStringAndCamelCase(r)}View.title`),
    noRobots: NO_SITEMAP_ROUTES.includes(r),
    noSitemap: NO_SITEMAP_ROUTES.includes(r),
    path: r,
    showDisclaimer: true,
    showDisclaimerDetail: true,
    showInstantMatchBrowseModule: r === SELL_MY_CAR_PATH,
    useUlezVrmChecker: ulezCheckerFooterRoutes.includes(r),
    wordpress: true,
  })))
  .concat(WORDPRESS_ROUTES.map((config) => ({
    cache: true,
    hideReviews: true,
    label: T.translate(`${config.section}View.title`),
    ...(config?.noRobots && { noRobots: config.noRobots }), // TODO - remove after testing, added for /wp paths
    path: config.path,
    wordpress: true,
    ...(config?.zendeskWidget && { zendeskWidget: config.zendeskWidget }),
  })))
  .concat(CUSTOMER_HUB_PATHS.map((route) => ({
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('carValueTrackerView.title'),
    meta: {
      description: T.translate('carValueTrackerView.metaDescription'),
      title: T.translate('carValueTrackerView.metaTitle'),
    },
    noRobots: true,
    path: route,
    showDisclaimer: false,
    showDisclaimerDetail: false,
    showTrackerDisclaimer: route === CUSTOMER_HUB_RESULT_ROUTE,
    ...(route === CUSTOMER_HUB_VALUATION_ROUTE && { noRobots: false, wordpress: true }),
  })))
  .concat(MOT_PAGE_ROUTES.map((route) => ({
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('motCheckView.title'),
    meta: {
      description: T.translate('motCheckView.metaDescription'),
      title: T.translate('motCheckView.metaTitle'),
    },
    path: route,
    showDisclaimer: true,
    showDisclaimerDetail: false,
    ...(route === MOT_VALUATION_ROUTE && { wordpress: true }),
  })))
  .concat(TAX_PAGE_ROUTES.map((route) => ({
    hideReviews: true,
    hideVehicleLookup: true,
    label: T.translate('taxCheckView.title'),
    meta: {
      description: T.translate('taxCheckView.metaDescription'),
      title: T.translate('taxCheckView.metaTitle'),
    },
    path: route,
    showDisclaimer: true,
    showDisclaimerDetail: false,
    ...(route === TAX_VALUATION_ROUTE && { wordpress: true }),
  })))
  .concat(SEO_PAGES_ROUTES.map((r) => ({
    hideReviews: true,
    meta: {
      description: T.translate(`seoPage${stripStringAndCamelCase(r)}.description`),
      title: T.translate(`seoPage${stripStringAndCamelCase(r)}.title`),
    },
    path: r,
  })));

// eslint-disable-next-line import/prefer-default-export
export { routes };
